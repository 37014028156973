import React from 'react';

import Methods from 'Libraries/CommonMethodsUI';

interface Props {
    className: string;
    id: string;
    type: any;
    autoComplete?: string;
    onChange: (e: any) => void;
    keyPress?: (e: any) => void;
    name?: string;
    value?: string | number | undefined;
    placeholder?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    required?: boolean;
    isHandleOnBlur?: boolean;
    label?: string;
}

export const Input:React.FC<Props> = (props) => {

    const { placeholder, className, id, type, onChange, value, autoComplete, inputRef, keyPress, name, required, isHandleOnBlur, label, ...rest } = props;

    const handleOnBlur = (val: string) => {
        // if user enters number with text, convert it to number else do nothing. example 1M should be 1,000,000
        if (val && /[a-zA-Z]/g.test(val)) {
            let num = val?.split(/[a-zA-Z]/g);
            let wrd = val?.split(/[0-9]/g);
            const converted = Methods.wordsToNumber(wrd[wrd.length - 1], num[0], val);
            onChange({ target: { value: converted.toLocaleString('en-US') } });
        }
    };

    return (
        <div>
            <span className="text-xs font-normal mb-1 block text-[#8D93A1] leading-[18px]"> {label} </span>
            <input
                name={name}
                id={id}
                type={type}
                value={value}
                className={`
                    ${className} block caret-black w-full text-black appearance-none rounded-lg border border-none px-3 
                    placeholder-[#B1BBC6] bg-[#F7F9FC] shadow-sm focus:border-gray-300 focus:outline-none h-12 input-modify
                    lg:text-sm md:text-sm sm:text-sm text-xs
                `}
                placeholder={placeholder}
                autoComplete={autoComplete}
                onChange={(e) => onChange(e)}
                onKeyPress={(e) => keyPress ? keyPress(e) : null}
                ref={inputRef}
                required={required ? required : false}
                onBlur={() => isHandleOnBlur && handleOnBlur(value as any)}
                {...rest} 
            />
        </div>
    );
};
