import React from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'Libraries/State.Interfaces';

interface Props {
    total: number;
    totalPages: number;
    onPageChange: (p: number) => void;
    onDisplayChange: (n: number) => void;
    currentPage: number;
    listLength: number;
    perPage: number;
    maxInput?: number;
}

interface IUsePagination {
    totalCount: number;
    siblingCount: number;
    currentPage: number;
    perPage: number;
}

const DOTS: string = '...';

export const usePagination = ({
    totalCount,
    siblingCount = 1,
    currentPage, 
    perPage
}: IUsePagination) => {

    const range = (start: number, end: number) => {
        let length: number = end - start + 1;
        /*
            Create an array of certain length and set the elements within it from
          start value to end value.
        */
        return Array.from({ length }, (_, idx) => idx + start);
    };

    const paginationRange = React.useMemo(() => {
        const totalPageCount: number = Math.ceil(totalCount / perPage);

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers: number = siblingCount + 5;
    
        /*
          Case 1:
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPageCount) {
          return range(1, totalPageCount);
        }
        
        /*
            Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        */
        const leftSiblingIndex: number = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex: number = Math.min(
          currentPage + siblingCount,
          totalPageCount
        );
    
        /*
          We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        */
        const shouldShowLeftDots: boolean = leftSiblingIndex > 2;
        const shouldShowRightDots: boolean = rightSiblingIndex < totalPageCount - 2;
    
        const firstPageIndex: number = 1;
        const lastPageIndex: number = totalPageCount;
    
        /*
            Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
          let leftItemCount: number = 2 + 2 * siblingCount;
          let leftRange = range(1, leftItemCount);
    
          return [...leftRange, DOTS, totalPageCount];
        }
    
        /*
            Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {
          
          let rightItemCount: number = 2 + 2 * siblingCount;
          let rightRange = range(
            totalPageCount - rightItemCount + 1,
            totalPageCount
          );
          return [firstPageIndex, DOTS, ...rightRange];
        }
         
        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
          let middleRange = range(leftSiblingIndex, rightSiblingIndex);
          return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
        
        return [];
    }, [totalCount, siblingCount, currentPage, perPage]);
  
    return paginationRange;
};


const PaginatedRecord:React.FC<Props> = ({ totalPages, total, onPageChange, currentPage, onDisplayChange, listLength, perPage, maxInput }) => {

    const [input, setInput] = React.useState<number>(0);

    const sideBarState: boolean = useSelector((state: IRootReducer) => state.activityReducer.sideBarState);

    const paginationRange: any[] = usePagination({ currentPage, totalCount: total, siblingCount: 1, perPage });

    function updateResultRange(): { start:  number, end:  number } {
        const start = (currentPage - 1) * perPage + 1;
        const end = Math.min(start + perPage - 1, total); // Total number of results is 18 in this example
        return { start, end };
    }

    React.useEffect(() => {
        setInput(listLength);
    }, [listLength]);
    
    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange?.length < 1) {
        return null;
    }

    let lastPage = paginationRange[paginationRange?.length - 1];

    return (
        <div className={`fixed bottom-0 ${ sideBarState ? 'left-0 lg:left-60 xl:left-60' : 'left-0 lg:left-20 xl:left-20' } right-0`}>
            <div className="flex items-center justify-between border-t-2 border-[#DFDFDF] bg-white px-3 py-2">                
                <div className="flex flex-1 items-center justify-between">
                    <div className='sm:inline-flex md:inline-flex lg:inline-flex xl:inline-flex hidden'>
                        <p className="text-xs md:text-sm lg:text-sm xl:text-sm text-[#6C757D]">
                            Showing <span className="font-medium">{updateResultRange().start}</span> to <span className="font-medium">{updateResultRange().start + input - 1}</span> of{' '}
                            <span className="font-medium">{total}</span> results
                        </p>
                    </div>
                    <div className='w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto'>
                        <nav className="isolate inline-flex rounded-md items-center w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto" aria-label="Pagination">
                            <div className='inline-flex mr-8 items-center'>
                                <p className="text-sm text-[#6C757D] font-medium mr-1">Display</p>
                                <input title="Display" type='number' min={1} max={maxInput ? maxInput : 10} value={input} onChange={(e) => {
                                    if (parseInt(e.target.value) <= total) {
                                        setInput(parseInt(e.target.value));
                                        onDisplayChange(parseInt(e.target.value));
                                    }
                                }} className='border-[#DFDFDF] border-2 w-16 ring-0 h-8 outline-0 shadow-none rounded-md' />
                            </div>
                            <button disabled={currentPage === 1} type="button" onClick={() => onPageChange(currentPage - 1)}
                                className="relative ml-auto inline-flex justify-center items-center mr-1 rounded-md h-5 w-5 md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-8 xl:h-8 text-black bg-[#DFDFDF] hover:bg-gray-200 focus:z-20 focus:outline-offset-0"
                            >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                            { paginationRange.map((p: number | string, i: number) => (
                                <button type="button" key={i} disabled={p === '...'}
                                    className={`relative inline-flex justify-center mr-1 text-xs md:text-sm lg:text-sm xl:text-sm items-center rounded-md h-5 w-5 md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-8 xl:h-8 focus:z-20 focus:outline-offset-0
                                        ${p === currentPage ? 'text-white bg-red-600' : 'text-black bg-white hover:bg-gray-100'}
                                    `}
                                    onClick={() => onPageChange(Number(p))}
                                >
                                    {p}
                                </button>
                            )) }
                            <button disabled={currentPage === lastPage} type="button" onClick={() => onPageChange(currentPage + 1)}
                                className="relative inline-flex justify-center items-center rounded-md h-5 w-5 md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-8 xl:h-8 text-black bg-[#DFDFDF] hover:bg-gray-200 focus:z-20 focus:outline-offset-0"
                            >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon className="h-5 w-5 " aria-hidden="true" />
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaginatedRecord;
