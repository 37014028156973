import React, { FC, ComponentType } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { NavigateFunction, useNavigate } from "react-router-dom";
import { IReducers } from 'Libraries/State.Interfaces';
import { IUserProfile } from 'Libraries/Interfaces';

export interface IWithAuthentication {
    user?: IUserProfile;
    navigate?: NavigateFunction;
    dispatch?: (args?: any) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const withAuthentication = <ComponentProps extends Props>(WrappedComponent: ComponentType<ComponentProps & Props>, redirectRoute: string, routeId: number) => {

    const WithProtectedWrapper:FC<ComponentProps & Props> = (props) => {

        const state = useSelector((state: IReducers) => state.loginReducer);
        const dispatch = useDispatch();
        const navigate = useNavigate();

        return <WrappedComponent {...props} dispatch={dispatch} navigate={navigate as NavigateFunction} user={state.user} />;
    };

    return WithProtectedWrapper;
};

export default withAuthentication;
