import * as React from "react";

import './App.css';

import { Routes, Route } from "react-router-dom";
import { Paths } from "Libraries/Route";
import { Redirect } from "Routes/Redirect";
import { useDispatch, useSelector } from "react-redux";
import ApiRequest from "Services/ApiRequest";

import UploadFileViaEmail from "Screens/Dashboard/UploadFileViaEmail";
import ReportedOccupancy from 'Screens/Reported/Occupancy';
import ReportedConstruction from 'Screens/Reported/Construction';
import StpDataForm from "Screens/Stp/DataForm";
import { setPasswordRulesAction } from "Redux/Action";
import { MainLoader } from "Components/Loader/Loader";
import { IReducers } from "Libraries/State.Interfaces";
import { TokenService } from "Libraries/TokenService";
import MasterLayout from "Hoc/MasterLayout";

const Users = React.lazy(() => import("Screens/Users/Users"));
const Invoice = React.lazy(() => import("Screens/Invoice/Invoice"));
const Subscriptions = React.lazy(() => import('Screens/Subscriptions/Subscriptions'));
const TemplateHistory = React.lazy(() => import('Screens/TemplateHistory/TemplateHistory'));
const DashboardScreen = React.lazy(() => import('Screens/Dashboard/Content'));
const VersionList = React.lazy(() => import('Screens/CnVersions/VersionList'));
const CompanyScreen = React.lazy(() => import('Screens/Company/Content'));
const Triage = React.lazy(() => import('Screens/Triage/Triage'));
const LoginScreen = React.lazy(() => import('Screens/Login/Content'));
const SignUpScreen = React.lazy(() => import('Screens/SignUp/Content'));
const ForgotPasswordScreen = React.lazy(() => import('Screens/ForgotPassword/Content'));
const ForgotPasswordConfirmScreen = React.lazy(() => import('Screens/ForgotPasswordConfirm/Content'));
const Stp = React.lazy(() => import('Screens/Stp/Stp'));
const ReportAnalytics = React.lazy(() => import('Screens/Report/ReportAnalytics'));
const ReportAnalyticsBE = React.lazy(() => import('Screens/Report/ReportAnalyticsBE'));
const Settings = React.lazy(() => import('Screens/Settings/Settings'));

function SuspenseRoute({ children, isLoggedIn }: { children: JSX.Element | React.ReactNode, isLoggedIn: boolean; }) {
  return (
    <React.Suspense fallback={<MainLoader isLoggedIn={isLoggedIn} />}>
      {children}
    </React.Suspense>
  )
}

function App(): JSX.Element {

  const dispatch = useDispatch();
  const state = useSelector((state: IReducers) => state.loginReducer);

  React.useEffect(() => {
    getPasswordRules();
  }, []);

  async function getPasswordRules(): Promise<void> {
    const { data } = await ApiRequest.getPasswordRules();
    dispatch(setPasswordRulesAction(data.password_rules));
  }

  if(!state?.isLoggedIn || !TokenService.getAccessToken()) {
    return (
      <Routes>
        <Route path={'/'} element={<Redirect to={Paths.login} />} />
        <Route path={Paths.login} element={<SuspenseRoute isLoggedIn={false}> <LoginScreen /> </SuspenseRoute>} />
        <Route path={Paths.signup} element={<SuspenseRoute isLoggedIn={false}> <SignUpScreen /> </SuspenseRoute>} />
        <Route path={Paths.forgotPassword} element={<SuspenseRoute isLoggedIn={false}> <ForgotPasswordScreen /> </SuspenseRoute>} />
        <Route path={Paths.forgotPasswordConfirm} element={<SuspenseRoute isLoggedIn={false}> <ForgotPasswordConfirmScreen /> </SuspenseRoute>} />

        <Route path="*" element={<Redirect to={Paths.login} />} />
      </Routes>
    )
  }

  return (
    <MasterLayout dispatch={dispatch} userEmail={state?.user?.email ?? ""}>
      <Routes>
        <Route path={Paths.dashboard}>
          <Route index element={<SuspenseRoute isLoggedIn={true}> <DashboardScreen /> </SuspenseRoute>} />
          <Route 
            path={Paths.controlNumber} element={<SuspenseRoute isLoggedIn={true}><VersionList /></SuspenseRoute>} 
          />
          <Route path={`${Paths.controlNumber}/${Paths.version}${Paths.report}`}>
            <Route index element={<SuspenseRoute isLoggedIn={true}><ReportAnalytics /></SuspenseRoute>} />
            <Route path={Paths.systematic} element={<SuspenseRoute isLoggedIn={true}><ReportAnalyticsBE /></SuspenseRoute>} />
          </Route>
          <Route path={`${Paths.controlNumber}/${Paths.version}${Paths.email}`} element={<SuspenseRoute isLoggedIn={true}><UploadFileViaEmail bottom={2} /></SuspenseRoute>} />
        </Route>
        <Route path={Paths.reportedConstruction} element={<SuspenseRoute isLoggedIn={true}><ReportedConstruction /></SuspenseRoute>} />
        <Route path={Paths.reportedOccupancy} element={<SuspenseRoute isLoggedIn={true}><ReportedOccupancy /></SuspenseRoute>} />
        <Route path={Paths.templateHistory} element={<SuspenseRoute isLoggedIn={true}><TemplateHistory /></SuspenseRoute>} />
        <Route path={Paths.triage} element={<SuspenseRoute isLoggedIn={true}><Triage /></SuspenseRoute>} />
        <Route path={Paths.users} element={<SuspenseRoute isLoggedIn={true}><Users /></SuspenseRoute>} />
        <Route path={Paths.subscription} element={<SuspenseRoute isLoggedIn={true}><Subscriptions /></SuspenseRoute>} />
        <Route path={Paths.invoice} element={<SuspenseRoute isLoggedIn={true}><Invoice /></SuspenseRoute>} />
        {/* Stp ==>> Straight Through Processing */}
        <Route path={Paths.stp}>
          <Route index element={<SuspenseRoute isLoggedIn={true}><Stp /></SuspenseRoute>} />
          <Route path={Paths.controlNumber} element={<SuspenseRoute isLoggedIn={true}><Stp /></SuspenseRoute>} />
          <Route path={Paths.form} element={<SuspenseRoute isLoggedIn={true}><StpDataForm /></SuspenseRoute>} />
        </Route>
        <Route path={Paths.companies} element={<SuspenseRoute isLoggedIn={true}><CompanyScreen /></SuspenseRoute>} />
        <Route path={Paths.settings} element={<SuspenseRoute isLoggedIn={true}><Settings /></SuspenseRoute>} />
        
        <Route path="*" element={<Redirect to={Paths.dashboard} />} />
      </Routes>
    </MasterLayout>
  );
}

export default App;
