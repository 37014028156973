import React, {useState} from 'react';

import {FaEye, FaEyeSlash} from "react-icons/fa";
import { HiInformationCircle } from "react-icons/hi2";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'Libraries/State.Interfaces';

interface Props {
    label?: string;
    id: string;
    name: string;
    placeholder: string;
    className: string;
    onChange: (e: any) => void;
    keyPress?: (e: any) => void;
    value?: string | number;
    autoComplete?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    hidePasswordInfo?: boolean;
}

export const PasswordField:React.FC<Props> = (props) => {
    const { id, name, placeholder, className, onChange, value, autoComplete, inputRef, keyPress, hidePasswordInfo, label, ...rest } = props;

    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const passwordRules = useSelector((state: IRootReducer) => state.activityReducer.passwordRules);
    
    return (
        <div className="relative">
            <span className="text-xs font-normal mb-1 block text-[#8D93A1] leading-[18px]"> {label} </span>
            <input
                name={id}
                id={name}
                type={`${isPasswordVisible ? 'text' : 'password'}`}
                value={value}
                className={`${className} block caret-black w-full text-black appearance-none rounded-lg border border-none px-3 
                placeholder-[#B1BBC6] bg-[#F7F9FC] shadow-sm focus:border-gray-300 focus:outline-none h-12 input-modify 
                lg:text-sm md:text-sm sm:text-sm text-xs
                `}
                placeholder={placeholder}
                autoComplete={autoComplete}
                onChange={(e) => onChange(e)}
                onKeyPress={(e) => keyPress ? keyPress(e) : null}
                ref={inputRef}
                {...rest} 
            />
            <div className="absolute cursor-pointer top-9 right-2 text-[#8D93A1] flex flow-row items-center justify-start">
                {!isPasswordVisible ? 
                    <button name='show password' title='show password' onClick={()=> setIsPasswordVisible(pw => !pw)}><FaEyeSlash size={18} /> </button>
                    : 
                    <button name='hide password' title='hide password' onClick={()=> setIsPasswordVisible(pw => !pw)}><FaEye size={18} /></button>
                }
                
                { !hidePasswordInfo && (
                    <HiInformationCircle data-tooltip-id={name+"password-rules"} size={18} className="ml-2" />
                )}

                <ReactTooltip 
                    arrowColor='#383838' id={name+"password-rules"} opacity={1} closeOnEsc openOnClick 
                    className='z-20 overflow-hidden' place="top" 
                    noArrow={true} clickable={true} variant="dark" 
                >
                    <span className="text-sm text-left mb-2 font-semibold text-white opacity-90">Password Rules</span>
                    <ul className="list-inside list-disc text-white opacity-90">
                        { passwordRules.map((rule: string, ind: number) => (
                            <li key={ind} className="text-sm font-normal"> {rule} </li>
                        ))}
                    </ul>
                </ReactTooltip>
            </div>
        </div>
    );
};
