import { Fragment, useState, useEffect } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Tabs } from 'Components/Tabs/Tabs';
import { CommentTab } from './Comments';
import { HistoryTab } from './History';
import OutsideClickHandler from 'react-outside-click-handler';

interface IProps {
    open: boolean; 
    setOpen: (f: boolean) => void;
    controlNumber: string;
    selectedVersion: any;
    bottomClass: string;
}

export const RightSidePanel: React.FC<IProps> = ({ 
    open, setOpen, selectedVersion, controlNumber, bottomClass
}) => {
    
    const [activeTab, setActiveTab] = useState<number>(1);

    useEffect(() => {
        if (selectedVersion && activeTab !== 1) {
            setActiveTab(1);
        }
    }, [selectedVersion]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-0" onClose={() => null}>
                <div className="fixed top-0 right-0 bottom-0 w-4/5" />

                <div className="fixed top-0 right-0 bottom-0 overflow-hidden">
                    <div className="absolute top-0 right-0 bottom-0 overflow-hidden">
                        <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
                            <div className={`pointer-events-none fixed ${bottomClass} right-5 flex max-w-full pl-10`} style={{ top: 64 }}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                <Dialog.Panel className="pointer-events-auto w-80 flex flex-col h-full gap-y-5">
                                    <CommentTab versionId={selectedVersion?.version} id={selectedVersion?.id} controlNumber={controlNumber} />
                                    <div className="relative flex-1 bg-white rounded-t-xl">
                                        <MainTitle title="History" />
                                        <HistoryTab versionId={selectedVersion?.version} controlNumber={controlNumber} />
                                    </div>
                                </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </OutsideClickHandler>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

function MainTitle({ title }: {title: string}) {
    return (
        <div className="border-b border-[#D9E1E7] sticky top-0 z-10 backdrop-blur backdrop-filter bg-white bg-opacity-75 px-4">
            <span className='text-xs sm:text-sm md:text-base lg:text-base text-[#0F172A] font-medium leading-6 py-3 block'>{title}</span>
        </div>
    );
}