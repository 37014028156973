import * as React from 'react';

import SidePanel from "Components/SidePanel/SidePanel";
import TopBar from "Components/TopBar/TopBar";
import Modal from 'Components/Modal/Modal';
import { modalScreensEnum } from 'Libraries/Enums';
import ChangePasswordScreen from 'Screens/ChangePassword/Content';
import { IRootReducer } from "Libraries/State.Interfaces";
import { useSelector } from 'react-redux';
import { sideBarONOFFAction } from 'Redux/Action';
import { Images } from 'Libraries/Images';
import { useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';

export default function MasterLayout(props: { 
    children: JSX.Element; isReportTab?: boolean; 
    dispatch: React.Dispatch<AnyAction>
    userEmail: string;
}): JSX.Element {

    const navigate: any = useNavigate();

    const { loader, screenTitle, sideBarState: open } = useSelector((state: IRootReducer) => state.activityReducer);

    const [openModal, setOpenModal] = React.useState<{ flag: boolean, key: string }>({flag: false, key: ''});

    return(
        <DocumentTitle title={screenTitle}>
            <SidePanel 
                isProfile={openModal.key === modalScreensEnum[modalScreensEnum.profile]} 
                isSideBarOpen={open} setOpen={(f: boolean) => {
                    setOpenModal({flag: false, key: ''});
                    props.dispatch(sideBarONOFFAction(f));
                }}
            />
            <div className={`flex flex-1 bg-[#F5F5F5] flex-col ${open ? 'md:pl-60 lg:pl-60 xl:pl-60' : 'md:pl-20 lg:pl-20 xl:pl-20'}  h-screen relative`}>
                <TopBar isSideBarOpen={open} userEmail={props.userEmail ?? ''} setOpenModal={(flag, key) => {
                    setOpenModal({flag, key});
                    if (key === modalScreensEnum[modalScreensEnum.profile]) {
                        props.dispatch(sideBarONOFFAction(true));
                    }
                }} navigate={navigate} mainTitle={screenTitle} />
                <main className={props?.isReportTab ? "py-0 overflow-y-hidden" : "py-6 overflow-y-hidden"}>
                    <div className='w-full h-16 invisible'>_</div>
                    {props.children}
                </main>
            </div>

            {openModal.flag &&
                <Modal open={openModal.flag && openModal.key === modalScreensEnum[modalScreensEnum.password]} setOpen={(e: boolean)=> setOpenModal({ ...openModal, flag: e })}
                        isSmall={openModal.key === modalScreensEnum[modalScreensEnum.password]}>
                    { openModal.key === modalScreensEnum[modalScreensEnum.password] ?
                        <ChangePasswordScreen setOpen={(e: boolean)=> setOpenModal({ ...openModal, flag: e })} />
                    : null }
                </Modal>
            }

            {loader && (
                <div className="loader app-preloader">
                    <img alt="wizardlogo" src={Images.logo} width={90} height={90} />
                </div>
            )}
        </DocumentTitle>
    );
}
