import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Input } from 'Components/Input/Input';
import { Button } from 'Components/Button/Button';

interface Props {
    children: JSX.Element[] | JSX.Element | any;
    open: boolean;
    setOpen: (f: boolean) => void;
    isSmall?: boolean;
    isMedium?: boolean;
    modalWidthClass?: string;
}

const Modal: React.FC<Props>  = ({ open, setOpen, children, isSmall, isMedium, modalWidthClass }) => {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-30" onClose={() => setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-30 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className={`relative transform overflow-hidden py-4 rounded-2xl px-4 bg-white z-50
                            text-left shadow-xl transition-all sm:my-8 sm:w-full ${isSmall ? 'sm:max-w-lg' : isMedium ? 'sm:max-w-2xl' : `${modalWidthClass ? modalWidthClass : 'sm:max-w-5xl'}`}`}>
                                {children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export function DeleteOrAddPremiumModal({ children, onConfirm, status, onCloseModal }: { children: JSX.Element; onConfirm: (val?: any) => void; status: { id: number; value: string; }; onCloseModal?: () => void | undefined; }): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(status.id ? true : false);
  const [premiumValue, setPremiumValue] = useState<number | null>(null);

  function closeModal(): void {
    setIsOpen(false);
    if (onCloseModal) {
        onCloseModal();
    }
  }

  function openModal(): void {
    setIsOpen(true);
  }

  return (
    <>
        <div onClick={() => openModal()}>
            {children}
        </div>

        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => null}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                        <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-base text-center font-semibold leading-6 text-black"
                            >
                                { status.id === 7 ? (
                                    <Input
                                        id="premium" name="premium" value={premiumValue ?? 0} placeholder={"Enter premium value"} autoComplete={'off'}
                                        onChange={(e: any)=> {
                                            setPremiumValue(e.target.value);
                                        }} type="number"
                                        className={`block w-full caret-black text-black appearance-none rounded-lg border px-3 py-2 border-black 
                                        placeholder-black bg-transparent shadow-sm focus:border-gray-300 focus:outline-none sm:text-sm h-10 input-modify`}
                                    />
                                ) : 'Confirm' }
                            </Dialog.Title>
                            <div className="py-3 mt-3 border-t border-[#DEDFDF]">
                                <p className="text-sm text-[#6C757D] block text-center">
                                    { [5, 6].includes(status.id) ? `Are you sure you want to update the status to ${status.value}` : 
                                    status.id === 7 ? `Are you sure you want to ${status.value} this account` 
                                    : 'Are you sure you want to delete'}?
                                </p>
                            </div>

                            <div className="flex flex-row items-center justify-center">
                                {['Cancel', 'Confirm'].map((btn: string, i: number) => (
                                    <Button title={btn} onClick={() => {
                                        if (i === 0) {
                                            closeModal();
                                        } else {
                                            closeModal();
                                            onConfirm(premiumValue);
                                        }
                                    }} key={btn} className={`px-4 py-1 rounded-full hover:bg-opacity-60 transition-all duration-200 ${i === 0 ? 'bg-primary-red mr-5' : 'bg-primary-green'} text-sm text-white`} />
                                ))}
                            </div>
                        </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>
  )
}

export function ConfirmModal({ children, onConfirm, onCloseModal, message, isDisabledConfirm, className }: { 
    children: JSX.Element; onConfirm: () => void; message?: string; onCloseModal?: () => void | undefined; isDisabledConfirm: boolean;
    className?: string;
}): JSX.Element {
    const [isOpen, setIsOpen] = useState<boolean>(false);
  
    function closeModal(): void {
      setIsOpen(false);
      if (onCloseModal) {
          onCloseModal();
      }
    }
  
    function openModal(): void {
      setIsOpen(true);
    }
  
    return (
      <>
          <div className={className} onClick={() => openModal()}>
              {children}
          </div>
  
          <Transition appear show={isOpen} as={Fragment}>
              <Dialog as="div" className="relative z-10" onClose={() => null}>
                  <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                  >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>
  
                  <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                          <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                          >
                          <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                              <Dialog.Title
                                  as="h3"
                                  className="text-base text-center font-semibold leading-6 text-black"
                              >
                                Confirm
                              </Dialog.Title>
                              <div className="py-3 mt-3 border-t border-[#DEDFDF]">
                                  <p className="text-sm text-[#6C757D] block text-center">
                                     {message ? message : "Are you sure you want to proceed"}?
                                  </p>
                              </div>
  
                              <div className="flex flex-row items-center justify-center">
                                  {[
                                    { label: 'Cancel', isDisable: false },
                                    { label: 'Confirm', isDisable: isDisabledConfirm }
                                  ].map(({ label, isDisable }, i: number) => (
                                      <Button title={label} disabled={isDisable} onClick={() => {
                                        if (i === 0) {
                                            closeModal();
                                        } else {
                                            closeModal();
                                            onConfirm();
                                        }
                                      }} key={label} className={`px-4 py-1 rounded-full hover:bg-opacity-60 transition-all duration-200 ${i === 0 ? 'bg-primary-red mr-5' : 'bg-primary-green'} text-sm text-white`} />
                                  ))}
                              </div>
                          </Dialog.Panel>
                          </Transition.Child>
                      </div>
                  </div>
              </Dialog>
          </Transition>
      </>
    )
}

export default Modal;
