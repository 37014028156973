import { Disclosure } from '@headlessui/react';
import { AnimatePresence, easeOut, motion } from 'framer-motion';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

export default function DisclosureAccordion({ children, button }: { children: JSX.Element | any; button: JSX.Element; }): JSX.Element {
  return (
    <Disclosure as="div" className="w-full">
      {({ open }) => (
        <>
            <Disclosure.Button className="flex justify-between w-full">
                {button}
                {open ? (
                    <ChevronUpIcon className="w-5 h-5 text-purple-500" />
                ) : (
                    <ChevronDownIcon className="w-5 h-5 text-purple-500" />
                )}
            </Disclosure.Button>
            <div className="py-2">
                <AnimatePresence>
                    {open && (
                        <Disclosure.Panel
                            static
                            as={motion.div}
                            initial={{ opacity: 0, y: -24 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -24 }}
                            transition={{ duration: 0.2, ease: easeOut }}
                            className="origin-top"
                        >
                            { children }
                        </Disclosure.Panel>
                    )}
                </AnimatePresence>
            </div>
        </>
      )}
    </Disclosure>
  )
}
