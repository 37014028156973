import moment from "moment";
import Constants from "./Constants";
import { IMappedDataMatrix } from "./Interfaces";
import CryptoJS from "crypto-js";
import { Images } from "./Images";

class CommonMethods{
   public ValidateEmail = (value: string): boolean => {
        const pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        return pattern.test(value);
    };

  public ValidatePassword = (value: string): boolean => {
    const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{4,}$/;

    return pattern.test(value);
  };

  public wordsToNumber = (_word: string, _number: any, value?: string) => {
    const item: { value: number, symbol: string } | any = Constants.lookup.find((item: { value: number, symbol: string }) => _word.toUpperCase() === item.symbol);
    return item
      ? item?.value * Number(_number)
      : item?.value
      ? item?.value
      : value;
  };

  public unAuthorizaedLogout = (reload: boolean): void => {
    localStorage.clear();
    sessionStorage.clear();
    
    if (reload) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

   public arrayValuesSum = (array: any[]): number => {
        let sum = 0;
        for (let i = 0; i < array.length; i++) {
          if (typeof array[i] === "number" && isNaN(array[i])) {
            continue;
          }
          else if (typeof array[i] === "number") {
            sum = sum + parseFloat(array[i]);
          } else {
            sum = sum + 0;
          }
        }
        return sum;
    };

    public unKnownValueSwap = (dataset: any): any => {
      const index = dataset.labels?.findIndex((f: string) => f === 'Unknown');
      if (index !== -1) {
        const labels: string[] = [ ...dataset.labels.filter((f: string) => f !== 'Unknown'), 'Unknown' ];
        const datasets = dataset.datasets.map((v: any) => {
          return {
            ...v, data: [ ...v.data.filter((_: any, ind: number) => ind !== index), v.data[index] ]
          }
        }) ?? [];

        return { labels, datasets };
      }

      return dataset;
    }

    public numberWithCommas = (x: string | number): string => {
      return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

   public numberFormatter = (num: number, toFixed?: number) => {
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let item = Constants.lookup
        .slice()
        .reverse()
        .find((item) => num >= item.value);
        return item ? (num / item.value).toFixed(toFixed ? toFixed : 1).replace(rx, "$1") + item.symbol : "0";
    };

    public sumOfArrayObjectsForMappings = (arr: { cova: number; covb: number; covc: number; covd: number; label: string; }[]) => {
      return arr.reduce((acc, obj) => {
        acc.cova += obj.cova;
        acc.covb += obj.covb;
        acc.covc += obj.covc;
        acc.covd += obj.covd;
        return acc;
      }, { cova: 0, covb: 0, covc: 0, covd: 0 });
    };

    public classNames = (...classes: any): string => {
        return classes.filter(Boolean).join(' ');
    };

   public noDataAvailable = (str?: string): string => {
       return str ? str : 'N/A';
   }

  public scrollToSection = (id: string): void => {
    const ele = document.getElementById(id);
    if (ele) {
      ele.scrollIntoView({ behavior: 'smooth' });
    }
  }

  public rgbaToHex(r: number, g: number, b: number, a: number): string {
    // Ensure the RGB values are within the proper range
    r = Math.max(0, Math.min(255, r));
    g = Math.max(0, Math.min(255, g));
    b = Math.max(0, Math.min(255, b));
    
    // Convert each component to its hexadecimal representation
    let rHex = r.toString(16).padStart(2, '0');
    let gHex = g.toString(16).padStart(2, '0');
    let bHex = b.toString(16).padStart(2, '0');
    
    // Combine the hex components into one string
    return `0x${rHex}${gHex}${bHex}`;
  }

   public sort = (arr: any, key: string, ascending: boolean, callbacks: (arg: any) => void): void => {
       if(ascending)  callbacks(arr.sort((a: any,b: any) => a[key]-b[key]));
       else callbacks(arr.sort((a: any,b: any) => a[key]-b[key]).reverse());
   }

   public removeUnderscore = (str: string): string | null => {
       return str ? str.split('_').join(' ') : str;
   };

   public getfileName = (str: string): string | undefined => {
       return str ? str.split("/").pop() : '';
   };

    public getEasternTimeZone = (date?: string, fr?: string): string => {
        const format: string = fr ? fr : "MMMM Do YYYY, h:mm:ss a";
        return date ? moment(date).utc().subtract(5, 'hours').format(format) : '';
    };

    public reportExportTime = (): string => {
      return moment(new Date()).utc().format("MM-DD-YYYY");
    }

    public answerBoolean = (value: boolean | any): string => {
        return value ? 'Yes' : 'No';
    }

    public capitalize = (letter: string | any): string => {
        return letter ? letter.charAt(0).toUpperCase() + letter.slice(1) : '';
    }

    public obscureEmail = (email: string): string => {
        const [name, domain] = email.split('@');
        return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
    };

    public getDateByFormat = (date: string, fr?: string): string => {
        return moment(date).format(fr);
    };

    public convertUTCtoLocalDate = (date: string): string => {
        const utcDate = new Date(date);

        // Convert UTC date to user's local date and time
        const userLocalDate = new Intl.DateTimeFormat(navigator.language, {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            // timeZoneName: 'shortOffset'
        }).format(utcDate);

        return userLocalDate;
    }

    public randomColor = (): string => {
        return '#' + Math.floor(Math.random()*16777215).toString(16);
    }

    public formatBytes = (bytes: number, decimals = 2): string => {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    public yearBuiltDataVisulizationPerform = (
        dataArry: IMappedDataMatrix[]
      ): IMappedDataMatrix[] => {
        const condition1 = dataArry?.filter((f) => parseInt(f.label) < 1995);
        const condition2 = dataArry?.filter(
          (f) => parseInt(f.label) > 1994 && parseInt(f.label) < 2002
        );
        const condition3 = dataArry?.filter(
          (f) => parseInt(f.label) > 2001 && parseInt(f.label) < 2009
        );
        const condition4 = dataArry?.filter((f) => parseInt(f.label) > 2008);
        const conditionOthers = dataArry?.filter(
          (f) => f.label?.toString() === "" || typeof f.label === "string"
        );
    
        const conditions: IMappedDataMatrix[][] = [
          condition1,
          condition2,
          condition3,
          condition4,
          conditionOthers,
        ];
        const data: IMappedDataMatrix[] = [];
    
        for (let i = 0; i < conditions.length; i++) {
          if (conditions[i].length) {
            data.push({
              label: Constants.yearBuiltDataVisulization[i],
              cova: this.arrayValuesSum(conditions[i].map((c1) => c1.cova)),
              covb: this.arrayValuesSum(conditions[i].map((c1) => c1.covb)),
              covc: this.arrayValuesSum(conditions[i].map((c1) => c1.covc)),
              covd: this.arrayValuesSum(conditions[i].map((c1) => c1.covd)),
            });
          }
        }
    
        return data;
      };
    
      public storiesDataVisulizationPerform = (
        dataArry: IMappedDataMatrix[]
      ): IMappedDataMatrix[] => {
        const condition1 = dataArry?.filter((f) => parseInt(f.label) === 1);
        const condition2 = dataArry?.filter(
          (f) => parseInt(f.label) > 1 && parseInt(f.label) < 4
        );
        const condition3 = dataArry?.filter(
          (f) => parseInt(f.label) > 3 && parseInt(f.label) < 8
        );
        const condition4 = dataArry?.filter(
          (f) => parseInt(f.label) > 7 && parseInt(f.label) < 15
        );
        const condition5 = dataArry?.filter((f) => parseInt(f.label) > 14);
        const conditionOthers = dataArry?.filter(
          (f) => f.label?.toString() === "" || typeof f.label === "string"
        );
    
        const conditions: IMappedDataMatrix[][] = [
          condition1,
          condition2,
          condition3,
          condition4,
          condition5,
          conditionOthers,
        ];
        const data: IMappedDataMatrix[] = [];
    
        for (let i = 0; i < conditions.length; i++) {
          if (conditions[i].length) {
            data.push({
              label: Constants.storiesDataVisulization[i],
              cova: this.arrayValuesSum(conditions[i].map((c1) => c1.cova)),
              covb: this.arrayValuesSum(conditions[i].map((c1) => c1.covb)),
              covc: this.arrayValuesSum(conditions[i].map((c1) => c1.covc)),
              covd: this.arrayValuesSum(conditions[i].map((c1) => c1.covd)),
            });
          }
        }
    
        return data;
    };

    public getSumHelper = (data: any[]) => {
        let _total = 0;
        const itemArray = data.map((item) => {
          if (!item) return 0;
          if (item) {
            if (typeof item === "number") return item;
            if (/[a-zA-Z]/g.test(item)) return 0;
            let val: any = `${item}`.replace(/[&\/\\#,+\-()$~%.'":*?<>{}]/g, "");
            val = val ? parseInt(val) : 0;
            return val;
          } else {
            return 0;
          }
        });
        const sumWithInitial = itemArray.reduce(
          (previousValue, currentValue) =>
            parseInt(previousValue) + parseInt(currentValue),
          _total
        );
        return sumWithInitial;
    };

    public modifyMatrixData = (
        labels: any[],
        dataArry: IMappedDataMatrix[]
      ): IMappedDataMatrix[] => {

        if (!labels) {
          return [];
        }

        const _data: any[] = [];
        labels?.forEach((item) => {
          let total = dataArry.map((dataItm) => {
            const label: string = dataItm?.label?.toString()?.trim();
            if (item === label) {
              let a: any = dataItm.cova;
              let b: any = dataItm.covb;
              let c: any = dataItm.covc;
              let d: any = dataItm.covd;
              return { a: a, b: b, c: c, d: d };
            } else {
              return { a: 0, b: 0, c: 0, d: 0 };
            }
          });
          const sumCovA = this.getSumHelper(total.map((item) => item.a));
          const sumCovB = this.getSumHelper(total.map((item) => item.b));
          const sumCovC = this.getSumHelper(total.map((item) => item.c));
          const sumCovD = this.getSumHelper(total.map((item) => item.d));
          _data.push({
            cova: sumCovA,
            covb: sumCovB,
            covc: sumCovC,
            covd: sumCovD,
          });
        });
    
        return _data;
    };

    public removeDublicatesInArray = (arr: any[]): any[] => {
      return arr?.filter((x, i, a) => a?.indexOf(x) === i);
    };

    public encryptDecryptPassword = (
      password: string,
      encryption: boolean
    ): string => {
      let pass = "";
  
      if (encryption) {
        const cipherText = CryptoJS.AES.encrypt(password, Constants.secret);
        pass = cipherText.toString();
      } else {
        const bytes = CryptoJS.AES.decrypt(password, Constants.secret);
        const decrypted = bytes.toString(CryptoJS.enc.Utf8);
        pass = decrypted.toString();
      }
  
      return pass;
    };

    public getTomorrowDate = (): Date => {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow;
    };

    public getFileIcon = (name: string): any => {
      if (!name) {
        return Images.fileErrorIcon;
      }

      const extension: string | undefined = name?.toLowerCase()?.split('.')?.pop(); 

      if (extension === 'xls' || extension === 'xlsx' || extension === 'csv') {
        return Images.excelIcon;
      }
      else if (extension === 'pdf') {
        return Images.pdfIcon;
      }
      else if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
        return Images.imageIcon;
      }
      else if (extension === 'txt') {
        return Images.textFileIcon;
      }
      else {
        return Images.fileErrorIcon;
      }
    };

    public getFileIconWithIncludes = (name: string): any => {
      if (!name) {
        return Images.fileErrorIcon;
      }

      if (name.includes('.xls') || name.includes('.xlsx') || name.includes('.csv')) {
        return Images.excelIcon;
      }
      else if (name.includes('.pdf')) {
        return Images.pdfIcon;
      }
      else if (name.includes('.jpg') || name.includes('.png') || name.includes('.jpeg')) {
        return Images.imageIcon;
      }
      else if (name.includes('.txt')) {
        return Images.textFileIcon;
      }
      else {
        return Images.fileErrorIcon;
      }
    };
  
    public getKeyByValue = (object: { [key: string]: any; }, value: any): string => {
      return Object.keys(object).find(key => object[key] === value) as string;
    }

    public hslToRgb(h: number, s: number, l: number): string {
      s /= 100;
      l /= 100;
    
      let c = (1 - Math.abs(2 * l - 1)) * s;
      let x = c * (1 - Math.abs((h / 60) % 2 - 1));
      let m = l - c / 2;
      let r = 0, g = 0, b = 0;
    
      if (0 <= h && h < 60) {
        r = c; g = x; b = 0;
      } else if (60 <= h && h < 120) {
        r = x; g = c; b = 0;
      } else if (120 <= h && h < 180) {
        r = 0; g = c; b = x;
      } else if (180 <= h && h < 240) {
        r = 0; g = x; b = c;
      } else if (240 <= h && h < 300) {
        r = x; g = 0; b = c;
      } else if (300 <= h && h < 360) {
        r = c; g = 0; b = x;
      }
    
      r = Math.round((r + m) * 255);
      g = Math.round((g + m) * 255);
      b = Math.round((b + m) * 255);
    
      return `rgb(${r},${g},${b})`;
    }

    public parseQueryString(queryString: string): {[key: string]: any;} | null {

      if (!queryString) return null;

      // Remove any leading "?" if present
      queryString = queryString.startsWith('?') ? queryString.substring(1) : queryString;
  
      // Split the query string into key-value pairs
      const pairs = queryString.split('&');
  
      // Reduce the pairs into an object
      const result = pairs.reduce((acc: {[key: string]: any;}, pair: string) => {
          const [key, value] = pair.split('=');
          acc[decodeURIComponent(key)] = decodeURIComponent(value);
          return acc;
      }, {});
  
      return result;
  }

  public getGMTTimezone(): string {
    const formatter = new Intl.DateTimeFormat('en', { timeZoneName: 'short' });
    const parts: any[] = formatter.formatToParts();
  
    // Find the timezone part and replace "UTC" with "GMT" to match the format.
    const timezonePart = parts.find(part => part.type === 'timeZoneName').value;
    return Intl.DateTimeFormat().resolvedOptions().timeZone + ', ' + timezonePart.replace('UTC', 'GMT');
  }

  public getDynamicUrl = (): string => {
    const hostname = window.location.hostname;
    const basename: string = hostname.split('.')[0];

    const baseUrl = process.env.REACT_APP_API_URL; // Get the URL from the .env file

    if (!baseUrl) {
      throw new Error('Base URL is not defined in the environment file');
    }

    return baseUrl.replace('[basename]', basename); // Replace '[basename]' with the provided value
  };
}

const Methods = new CommonMethods();

export default Methods



