import React from 'react';

import {FaSpinner} from "react-icons/fa";
import { TbLoader3 } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { IRootReducer } from 'Libraries/State.Interfaces';

export interface Props {
    enhance: string;
    adjustment: boolean;
    style?: any;
}

export const SpinnerLoader:React.FC<Props> = ({ enhance, adjustment, style }) => {
    return (
        <div className={`w-full flex flex-row ${adjustment && 'justify-center items-center'}`}>
            <FaSpinner  className={`animate-spin ${enhance}`} style={style} />
        </div>
    );
};

export function MainLoader({ isLoggedIn }: { isLoggedIn: boolean; }): JSX.Element {
    const open: boolean = useSelector((state: IRootReducer) => state.activityReducer.sideBarState);

    return (
      <div role='presentation' className={`
        flex flex-col items-center justify-center absolute right-0 bottom-0 top-0 z-50 bg-transparent
        ${!isLoggedIn ? 'left-0' : (open ? 'left-60' : 'left-20')}
      `}>
        <TbLoader3 data-testid="loader-icon" className="w-10 h-10 md:w-16 md:h-16 lg:w-16 lg:h-16 xl:w-16 xl:h-16 animate-spin text-black" />
      </div>
    )
}
