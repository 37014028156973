import { SIDEBAR_ON_OFF, SET_PASSWORD_RULES, APP_LOADER, SET_SCREEN_TITLE } from "Redux/Types";
import { IActivityState, IAction } from "Libraries/State.Interfaces";

const initialState: IActivityState = {
    sideBarState: true,
    passwordRules: [],
    loader: false,
    screenTitle: "Submissions"
};

export const ActivityReducer = (state = initialState, action: IAction): IActivityState => {

    const { payload, type } = action;
    switch(type){
        case SIDEBAR_ON_OFF: {
            return {
                ...initialState,
                sideBarState: payload
            };
        }
        case SET_PASSWORD_RULES: {
            return {
                ...initialState,
                passwordRules: payload
            };
        }
        case APP_LOADER: {
            return {
                ...initialState,
                loader: payload
            };
        }
        case SET_SCREEN_TITLE: {
            return {
                ...initialState,
                screenTitle: payload
            };
        }
        default:
            return state;
    }
};
