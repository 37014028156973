import * as React from 'react';

import { Input } from 'Components/Input/Input';
import Dropdown, { DropdownWithCheckbox } from 'Components/Dropdown/Dropdown';
import { DatePicker } from 'Components/DateRange/DateRange';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import Constants from 'Libraries/Constants';
import { Checkbox } from 'Components/Checkbox/Checkbox';
import DisclosureAccordion from 'Components/Disclosure/Disclosure';
import { IDeductible, IPolicyTermLayer } from 'Libraries/Interfaces';

interface IPolicySectionProps {
    info: { [key: string]: any; };
    layers: IPolicyTermLayer[];
    onRemoveLayer: (ind: number) => void;
    onAddLayer: () => void;
    setPolicyInfo: (key: string, value: any) => void;
    setLayerValues: (ind: number, key: string, value: any) => void;
}

const fieldStyles: string = `block w-full caret-black text-black appearance-none rounded-lg border border-black px-3 placeholder-black bg-transparent shadow-sm focus:border-gray-300 focus:outline-none sm:text-sm input-modify`;

export function Divider({ label }:{ label: string; }): JSX.Element {
    return (
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-sm text-gray-500">{label}</span>
        </div>
      </div>
    )
}

function RequiredField({ type, ...props }: any): JSX.Element {
    if (type === 'dropdown') {
        return (
            <Dropdown {...props} styles={`${fieldStyles} justify-between py-2.5`} />
        );
    }

    if (type === 'date') {
        return (
            <DatePicker buttonStyles={`${fieldStyles} justify-between py-2.5`} {...props} />
        );
    }

    return (
        <Input {...props} className={`${fieldStyles} mb-3 py-2 h-10`} />
    );
} 

export function PolicyTermsSection(props: IPolicySectionProps): JSX.Element {
    const { info, layers, onAddLayer, onRemoveLayer, setPolicyInfo, setLayerValues } = props;

    return(
        <DisclosureAccordion button={<h5 className="text-xl font-medium">Policy Terms</h5>}>
            <section className="py-2">
                <h5 className="mb-3 text-lg font-normal">Policy Info</h5>
                <div className="grid grid-cols gap-x-4 grid-cols-3 mb-3">
                    <RequiredField 
                        placeholder="Underwriter" arr={[{ id: 1, name: "Momin" }]} isSmallIcon setData={(value: any) => setPolicyInfo("underwriter", value)}
                        type="dropdown"
                    />
                    <RequiredField type="date" label='Inception Date' selection={info['inceptionDate']} setSelection={(d: Date) => setPolicyInfo("inceptionDate", d)} />
                    <RequiredField type="date" label='Expiration Date' selection={info['expirationDate']} setSelection={(d: Date) => setPolicyInfo("expirationDate", d)} />
                </div>
                <div className="grid grid-cols gap-x-4 grid-cols-3">
                    {[
                    { label: "Insured Name", name: "insured" },
                    { label: "Policy #", name: "poicyNo" },
                    { label: "Branch", name: "branch" },
                    { label: "Producer", name: "producer" },
                    { label: "LOB", name: "lob" },
                    { label: "Premium", name: "premium" },
                    ].map((item, ind: number) => (
                        <div className="grid-cols-1" key={ind}>
                            <RequiredField
                                id={item.name} name={item.name} type="text" required={true} isHandleOnBlur={true}
                                value={info[item.name]} autoComplete="off" placeholder={`${item.label}`}
                                onChange={(e: any)=> {
                                    setPolicyInfo(item.name, e.target.value);
                                }}
                            />
                        </div>
                    ))}
                </div>
                
                <h5 className="text-lg font-normal mb-3">Policy/Layer Terms</h5>
                { layers.map((layer, ind: number) => (
                    <React.Fragment key={ind}>

                        <div className="flex flex-row items-center justify-between mb-1">
                            <span className="text-base">Perils</span>
                            <TrashIcon className="h-5 w-5 cursor-pointer text-primary-red" aria-hidden="true" onClick={() => onRemoveLayer(ind)} />
                        </div>
                        <div className="flex flex-row items-center flex-wrap justify-start mb-2">
                            <Checkbox 
                                className="mr-3" title="Select All" checked={layer.perils?.length === Constants.perilsMapping.length}
                                setChecked={(f) => {
                                    if (f) {
                                        setLayerValues(ind, "perils", Constants.perilsMapping.map((v) => v.peril));
                                    } else {
                                        setLayerValues(ind, "perils", []);
                                    }
                                }}
                            />
                            {Constants.perilsMapping.map((map) => (
                                <Checkbox 
                                    className="mr-3" title={map.name} key={map.id} checked={layer.perils?.includes(map.peril)}
                                    setChecked={(f) => {
                                        if (f) {
                                            setLayerValues(ind, "perils", [...layer.perils, map.peril]);
                                        } else {
                                            setLayerValues(ind, "perils", layer.perils.filter((f) => f !== map.peril));
                                        }
                                    }}
                                />
                            ))}
                        </div>
                        <div className="grid grid-cols gap-x-4 grid-cols-2">
                            <Dropdown 
                                placeholder="Deductible Type" arr={[
                                    {id: 1, name: "Blanket Deductible"}, {id: 2, name: "Maximum Deductible Amount"},
                                    {id: 3, name: "Minimum Deductible Amount"}, {id: 4, name: "No Deductible"}
                                ]} isSmallIcon setData={(value) => setLayerValues(ind, "deductibleType", value)}
                                styles={`${fieldStyles} justify-between py-2.5`}
                            />
                            <Input
                                id="deductibleAmount" name="deductibleAmount" type="text" required={true} isHandleOnBlur={true}
                                value={layer.deductibleAmount} autoComplete="off" placeholder="Deductible Amount"
                                onChange={(e)=> {
                                    setLayerValues(ind, "deductibleAmount", e.target.value);
                                }}
                                className={`${fieldStyles} mb-3 py-2 h-10`}
                            />
                        </div>
                        <div className="grid grid-cols gap-x-4 grid-cols-3">
                            {[
                            { label: "Policy Limit", name: "policyLimit", value: layer.policyLimit },
                            { label: "Part Of", name: "partOf", value: layer.partOf },
                            { label: "Attachment", name: "attachment", value: layer.attachment }
                            ].map((item, i: number) => (
                                <div className="grid-cols-1" key={i}>
                                    <Input
                                        id={item.name} name={item.name} type="text" required={true} isHandleOnBlur={true}
                                        value={item.value} autoComplete="off" placeholder={`${item.label}`}
                                        onChange={(e)=> {
                                            setLayerValues(ind, item.name, e.target.value);
                                        }}
                                        className={`${fieldStyles} mb-3 py-2 h-10`}
                                    />
                                </div>
                            ))}
                        </div>

                    </React.Fragment>
                )) }
                
                <PlusCircleIcon className="h-6 w-6 cursor-pointer ml-auto" aria-hidden="true" onClick={() => onAddLayer()} />
            </section>
        </DisclosureAccordion>
    );
}

export function LocationDeductiblesSection(props: { 
    locationDeductibles: IDeductible[], setLocationDeductibles: (ind: number, key: string, value: any) => void; 
    onRemoveLayer: (ind: number) => void; onAddLayer: () => void;
}): JSX.Element {
    const { locationDeductibles, setLocationDeductibles, onAddLayer, onRemoveLayer } = props;

    return(
        <DisclosureAccordion button={<h5 className="text-xl font-medium">Location Deductibles</h5>}>
            <section className="py-2">

                { locationDeductibles.map((obj: IDeductible, ind: number) => (
                    <React.Fragment key={ind}>    
                        <div className="flex flex-row items-center justify-between mb-1">
                            <span className="text-base">Perils</span>
                            <TrashIcon className="h-5 w-5 cursor-pointer text-primary-red" aria-hidden="true" onClick={() => onRemoveLayer(ind)} />
                        </div>
                        <div className="flex flex-row items-center flex-wrap justify-start mb-2">
                            <Checkbox 
                                className="mr-3" title="Select All" checked={obj.perils?.length === Constants.perilsMapping.length}
                                setChecked={(f) => {
                                    if (f) {
                                        setLocationDeductibles(ind, "perils", Constants.perilsMapping.map((v) => v.peril));
                                    } else {
                                        setLocationDeductibles(ind, "perils", []);
                                    }
                                }}
                            />
                            {Constants.perilsMapping.map((map) => (
                                <Checkbox 
                                    className="mr-3" title={map.name} key={map.id} checked={obj.perils?.includes(map.peril)}
                                    setChecked={(f) => {
                                        if (f) {
                                            setLocationDeductibles(ind, "perils", [...obj.perils, map.peril]);
                                        } else {
                                            setLocationDeductibles(ind, "perils", obj.perils.filter((f) => f !== map.peril));
                                        }
                                    }}
                                />
                            ))}
                        </div>

                        <div className={`grid grid-cols gap-x-4 grid-cols-3`}>
                            <DropdownWithCheckbox 
                                placeholder={"Geography"} menus={{
                                    'state': ['TX', 'PN'], 'county_tier': [1, 2, 0, 3], 'hu_zone': ['zone1'], 'eq_zone': ['zone2']
                                }} isSmallIcon
                                styles={`${fieldStyles} justify-between py-2.5`}
                                selections={{ ...obj.geography }}
                                setSelections={(key: string, flag: boolean, value: any) => {
                                    const geography: { [key: string]: any[]; } = { ...obj.geography };

                                    if (flag) {
                                        geography[key] = [ ...geography[key], value ];
                                        setLocationDeductibles(ind, "geography", geography);
                                    } else {
                                        geography[key] = geography[key].filter((f) => f !== value);
                                        setLocationDeductibles(ind, "geography", geography);
                                    }
                                }}
                            />
                            <Dropdown 
                                placeholder="Deductible Type" arr={[
                                    { id: 1, name: "Coverage" }, { id: 2, name: "Site" }
                                ]} isSmallIcon setData={(value) => setLocationDeductibles(ind, "deductibleType", value?.name)}
                                styles={`${fieldStyles} justify-between py-2.5`}
                            />
                            {[
                                { title: "Deductible", name: "deductible", value: obj.deductible, condition: obj?.deductibleType && obj?.deductibleType === "Site" },
                                { title: "Deductible A", name: "deductibleA", value: obj.deductibleA, condition: obj?.deductibleType && obj?.deductibleType !== "Site" },
                                { title: "Deductible B", name: "deductibleB", value: obj.deductibleB, condition: obj?.deductibleType && obj?.deductibleType !== "Site" },
                                { title: "Deductible C", name: "deductibleC", value: obj.deductibleC, condition: obj?.deductibleType && obj?.deductibleType !== "Site" },
                                { title: "Deductible D", name: "deductibleD", value: obj.deductibleD, condition: obj?.deductibleType && obj?.deductibleType !== "Site" }
                            ].filter((f) => f.condition).map((item) => (
                                <Input
                                    id={item.name} name={item.name} type="text" required={true} isHandleOnBlur={true}
                                    value={item.value} autoComplete="off" placeholder={item.title} key={item.name}
                                    onChange={(e)=> {
                                        setLocationDeductibles(ind, item.name, e.target.value);
                                    }}
                                    className={`${fieldStyles} mb-3 py-2 h-10 grid-cols-1`}
                                />
                            ))}
                        </div>
                    </React.Fragment>
                )) }
                
                <PlusCircleIcon className="h-6 w-6 cursor-pointer ml-auto" aria-hidden="true" onClick={() => onAddLayer()} />
            </section>
        </DisclosureAccordion>
    );
}

export function SubLimitAreaDeductiblesSection(props: {
    subLimitDeductibles: IDeductible[], setSubLimitDeductibles: (ind: number, key: string, value: any) => void; 
    onRemoveLayer: (ind: number) => void; onAddLayer: () => void;
}): JSX.Element {
    const { onAddLayer, onRemoveLayer, setSubLimitDeductibles, subLimitDeductibles } = props;

    return(
        <DisclosureAccordion button={<h5 className="text-xl font-medium">SubLimitArea Deductibles</h5>}>
            <section className="py-2">

                { subLimitDeductibles.map((obj: IDeductible, ind: number) => (
                    <React.Fragment key={ind}>                    
                        <div className="flex flex-row items-center justify-between mb-1">
                            <span className="text-base">Perils</span>
                            <TrashIcon className="h-5 w-5 cursor-pointer text-primary-red" aria-hidden="true" onClick={() => onRemoveLayer(ind)} />
                        </div>
                        <div className="flex flex-row items-center flex-wrap justify-start mb-2">
                            <Checkbox 
                                className="mr-3" title="Select All" checked={obj.perils?.length === Constants.perilsMapping.length}
                                setChecked={(f) => {
                                    if (f) {
                                        setSubLimitDeductibles(ind, "perils", Constants.perilsMapping.map((v) => v.peril));
                                    } else {
                                        setSubLimitDeductibles(ind, "perils", []);
                                    }
                                }}
                            />
                            {Constants.perilsMapping.map((map) => (
                                <Checkbox 
                                    className="mr-3" title={map.name} key={map.id} checked={obj.perils?.includes(map.peril)}
                                    setChecked={(f) => {
                                        if (f) {
                                            setSubLimitDeductibles(ind, "perils", [...obj.perils, map.peril]);
                                        } else {
                                            setSubLimitDeductibles(ind, "perils", obj.perils.filter((f) => f !== map.peril));
                                        }
                                    }}
                                />
                            ))}
                        </div>
                        <div className="grid grid-cols gap-x-4 grid-cols-3">
                            <DropdownWithCheckbox 
                                placeholder={"Geography"} menus={{
                                    'state': ['TX', 'PN'], 'county_tier': [1, 2, 0, 3], 'hu_zone': ['zone1'], 'eq_zone': ['zone2']
                                }} isSmallIcon
                                styles={`${fieldStyles} justify-between py-2.5`}
                                selections={{ ...obj.geography }}
                                setSelections={(key: string, flag: boolean, value: any) => {
                                    const geography: { [key: string]: any[]; } = { ...obj.geography };

                                    if (flag) {
                                        geography[key] = [ ...geography[key], value ];
                                        setSubLimitDeductibles(ind, "geography", geography);
                                    } else {
                                        geography[key] = geography[key].filter((f) => f !== value);
                                        setSubLimitDeductibles(ind, "geography", geography);
                                    }
                                }}
                            />
                            <Dropdown 
                                placeholder="Coverage Type" arr={[
                                    { id: 1, name: "Coverage" }, { id: 2, name: "Site" }, { id: 3, name: "Blanket" }
                                ]} isSmallIcon setData={(value) => setSubLimitDeductibles(ind, "deductibleType", value?.name)}
                                styles={`${fieldStyles} justify-between py-2.5`}
                            />
                            <Input
                                id="coverageValue" name="coverageValue" type="text" required={true} isHandleOnBlur={true}
                                value={obj.deductible} autoComplete="off" placeholder="Coverage Value"
                                onChange={(e)=> {
                                    setSubLimitDeductibles(ind, "deductible", e.target.value);
                                }}
                                className={`${fieldStyles} mb-3 py-2 h-10`}
                            />
                        </div>
                    </React.Fragment>
                )) }

                <PlusCircleIcon className="h-6 w-6 cursor-pointer ml-auto" aria-hidden="true" onClick={() => onAddLayer()} />
            </section>
        </DisclosureAccordion>
    );
}
