class Urls{
    public readonly loginUrl = '/user/login_with_password/';
    public readonly signUpUrl = '/user/';
    public readonly SignOutUrl = '';
    public readonly getUserDetails = '/user/get_user_details/';
    public readonly tokenRefreshUrl = '/user/token/refresh/';
    public readonly getSubmissionUrl = '/under_writer/submission/';
    public readonly getSubmissionVersionsUrl = '/under_writer/versions/';
    public readonly changePasswordUrl = '/user/change-password';
    public readonly resetPasswordRequestUrl = '/user/api/reset-password/';
    public readonly resetPasswordConfirmUrl = '/user/api/reset-password/confirm/';
    public readonly createLogUrl = '/under_writer/create-logs/';
    public readonly submissionAssign = '/under_writer/submission-assign/';
    public readonly submissionUpdate = '/under_writer/submission-update/';
    public readonly submissionGetUpdate = '/under_writer/submission-update-logs/';
    public readonly exportTemplate = '/sov_wizard/submission/export-user-template-download/';
    public readonly versionComments = '/under_writer/comment/';
    public readonly fetchHistory = '/under_writer/history/';
    public readonly exposureFiles = '/under_writer/attachment/';
    public readonly exposureTemplates = '/sov_wizard/submission/export-user-template-download/';
    public readonly dashboardCounts = '/under_writer/dashboard/';
    public readonly dublicateVersion = '/under_writer/versions/duplicate/';
    public readonly archiveVersion = '/under_writer/versions/archive/';
    public readonly uwEmail = '/under_writer/email-uw/';
    public readonly getDTReportJson = '/sov_wizard/submission/retrieve-data-transparency-report-json/';
    public readonly archiveSubmission = '/under_writer/submission/archive/';
    public readonly reportedConstructions = '/sov_wizard/reported-constructions/';
    public readonly reportedOccupancies = '/sov_wizard/reported-occupancy/';
    public readonly getCompanies = '/company/company-names/';
    public readonly generateInvoice = '/sov_wizard/generate-invoice-data/';
    public readonly updateStatus = '/under_writer/versions/update-status/';
    public readonly listCompanies = '/company/list-companies/';
    public readonly emailReplyList = '/under_writer/submission-reply-email-log/';
    public readonly getSTPSovsLists = '/stp/submission-stp/';
    public readonly postSovForSTPProcess = '/stp/upload-sov/';
    public readonly pendingVersions = '/under_writer/pending-versions/';
    public readonly pendingVersionAttachments = '/under_writer/pending-attachment/';
    public readonly approvePendingVersion = '/under_writer/pending-versions/approve-as-new-version/';
    public readonly approveAsControlNumber = '/under_writer/pending-versions/approve-as-new-account/';
    public readonly constOccPieChartData = '/reports/const-occ-pie-chart-data/';
    public readonly getPasswordRules = '/user/password_rules';
    public readonly reportMapData = '/reports/map-data/';
    public readonly reportGeoCodeData = '/reports/geocode-data/';
    public readonly reportConstructionsData = '/reports/construction-data/';
    public readonly reportOccupancyData = '/reports/occupancy-data/';
    public readonly reportCoverageData = '/reports/coverage-data/';
    public readonly reportDataQuality = '/reports/data-quality/';
    public readonly reportDataCompleteness = '/reports/data-completeness/';
    public readonly reportDataCoverageByGeography = '/reports/coverage-by-geography/';
    public readonly submissionReviewerAssign = '/under_writer/submission-reviewer-assign/';
}

const ApiUrls = new Urls();

export default ApiUrls;
