import * as React from 'react';

import { IRootReducer } from 'Libraries/State.Interfaces';
import { useSelector } from 'react-redux';
import { CiMinimize1 } from 'react-icons/ci';
import { FaRegWindowMinimize } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';
import { FiLink2 } from 'react-icons/fi';
import { MdDelete, MdInsertEmoticon, MdOutlineAttachFile } from 'react-icons/md';
import { Transition } from '@headlessui/react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { Paths } from 'Libraries/Route';
import { RightSidePanel } from 'Components/RightSidePanel/RightSidePanel';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Methods from 'Libraries/CommonMethodsUI';
import withAuthentication from 'Hoc/WithAuthentication';
import RichTextEditor from 'Components/RichText/RichText';
import ApiRequest from 'Services/ApiRequest';
import { toast } from 'react-toastify';
import { setScreenTitle } from 'Redux/Action';
import { Button } from 'Components/Button/Button';

const UploadFileViaEmail: React.FC<any> = ({ bottom, user, navigate, dispatch }) => {

    const { controlnumber, version } = useParams<{ controlnumber: string; version: string; }>();
    const { search } = useLocation();
    
    const [attachments, setAttachments] = React.useState<File[]>([]);
    const [isOpenPanel, setPanel] = React.useState<boolean>(true);

    const [minimize, setMinimize] = React.useState<boolean>(false);
    const [recipients, setRecipients] = React.useState<string>('');
    const [body, setBody] = React.useState<string>('');

    const sideBarState: boolean = useSelector((state: IRootReducer) => state.activityReducer.sideBarState);

    const selectFiles = (files: FileList | null | any): void => {
        const arr = [];
        for (let i = 0; i < files.length; i++) {
            arr.push(files[i]);
        }
        const all = [ ...arr, ...attachments ];
        const unique = [...new Map(all.map((m) => [m.name, m])).values()];
        setAttachments(unique);
    };

    const send = React.useCallback(async () => {
        if (body) {
            const formData = new FormData();
            formData.append("version", version as any);
            formData.append("control_number", controlnumber as any);
            formData.append("message", body);
            if (attachments.length > 0) {
                attachments.forEach((file) => {
                    formData.append("file", file);
                });
            }
            
            try {
                const res = await ApiRequest.sendUWEmail(formData);
                toast.success(res.data.message);
                setAttachments([]);
                setBody('');
            }
            catch (e) {}
        }
    }, [body, attachments, controlnumber, version]);

    React.useEffect(() => {
        dispatch(setScreenTitle(`Version ${version}.0 (${controlnumber})`));
    }, []);

    return (
        <div className='h-full px-3 sm:px-4 lg:px-6'>
            <Transition.Root show={true} as={React.Fragment}>
                <div style={{ bottom: bottom }} className={`fixed z-30 bg-[#FEFCFF] ${ sideBarState ? 'left-60' : 'left-20' } ${ isOpenPanel ? 'right-80' : 'right-0' }`}>
                    <div className='flex flex-col border-0'>
                        <div className='flex items-center justify-between bg-primary-grey-20 px-3 h-10'>
                            <span className="text-sm text-black text-center flex-1 font-semibold">New Message</span>
                            <div className='inline-flex items-center justify-start'>
                                <FaRegWindowMinimize className="text-black opacity-60 text-sm cursor-pointer" onClick={() => setMinimize(true)} />
                                <CiMinimize1 className="text-black opacity-60 text-lg font-semibold mx-3 cursor-pointer" onClick={() => setMinimize(false)} />
                                <NavLink to={{ pathname: `${Paths.dashboard}/${controlnumber}`, search }} className="opacity-60 text-sm cursor-pointer">
                                    <CgClose className="text-black" />
                                </NavLink>
                            </div>
                        </div>
                        <div className={`duration-200 transition-all ${ minimize ? 'hidden' : 'flex flex-col' }`}>
                            <div className='hidden items-center justify-between mx-3 h-10'>
                                <input
                                    type="text" name="recipients" id="recipients" placeholder="Recipients"
                                    value={recipients} onChange={(e) => setRecipients(e.target.value)}
                                    className="block w-1/2 p-0 border-0 bg-inherit text-sm text-black font-normal placeholder:text-black placeholder:opacity-50 focus:ring-0"                            
                                />
                                <span className='text-sm text-black text-opacity-50'>Cc Bcc</span>
                            </div>
                            <RichTextEditor value={body} setValue={setBody} id="" />
                            <br /><br /><br />
                            { attachments.length > 0 && (
                                <div className="flex flex-col px-3 w-4/5 max-h-24 pt-3 overflow-y-auto remove-scrollbar">
                                    { attachments.map((item: File, index) => (
                                        <div className='text-sm p-2 mb-2 bg-primary-grey-20 bg-opacity-60 rounded-md inline-flex items-center' key={index}>
                                            <span className="font-medium mr-1 text-primary-skyblue underline">{item.name}</span>
                                            <span className="font-medium text-black pr-3">({Methods.formatBytes(item.size)})</span>
                                            <button
                                                type="button" title="close"
                                                className="relative text-gray-400 ml-auto hover:text-gray-500 focus:outline-none"
                                                onClick={() => setAttachments(attachments.filter((_, i) => i !== index))}
                                            >
                                                <span className="absolute -inset-2.5" />
                                                <XMarkIcon className="h-4 w-4 font-semibold" aria-hidden="true" />
                                            </button>
                                        </div>
                                    )) }
                                </div>
                            )}
                            <div className='flex items-center justify-end mx-3 my-2'>
                                <Button title="Send" onClick={() => send()} className="inline-flex capitalize items-center text-sm font-semibold rounded-md bg-primary-green px-4 py-2 text-white shadow-sm" />
                                <label className='ml-3 relative'>
                                    <span><MdOutlineAttachFile className='cursor-pointer text-2xl text-primary-grey-10' /></span>
                                    <input multiple placeholder="" title='attachments' type='file' className="hidden" onChange={(e) => selectFiles(e.target.files)} />
                                </label>
                                <FiLink2 className='cursor-pointer text-2xl text-primary-grey-10 ml-3' />
                                <MdInsertEmoticon className='cursor-pointer text-2xl text-primary-grey-10 ml-3' />
                                <MdDelete className='ml-3 cursor-pointer text-2xl text-primary-grey-10' />
                            </div>
                        </div>
                    </div>
                </div>
            </Transition.Root>

            <RightSidePanel bottomClass="bottom-0" selectedVersion={version} open={isOpenPanel} setOpen={() => setPanel(false)} controlNumber={controlnumber as any} />
        </div>
    );
}

export default withAuthentication(UploadFileViaEmail, Paths.login, 1);
