import axios, { AxiosInstance } from "axios";
import { toast } from "react-toastify";
import Constants from "Libraries/Constants";
import Methods from "Libraries/CommonMethodsUI";
import { configureStore } from "Redux/Store";
import { isLogoutAction } from "Redux/Action";

function httpErrorHandler(error: any) {
  if (error === null) throw new Error('Unrecoverable error!! Error is null!')
  if (axios.isAxiosError(error)) {
    //here we have a type guard check, error inside if will be treated as AxiosError
    const response = error?.response
    const request = error?.request

    if (error.code === 'ERR_NETWORK') {
      toast.warning('connection problems..');
    } else if (error.code === 'ERR_CANCELED') {
      toast.error('connection canceled..');
    }

    if (response) {
      //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
      const statusCode = response?.status
      if (statusCode === Constants.apiStatuses.API_DOES_NOT_EXIST) {
        console.log('The requested resource does not exist or has been deleted');
      } else if (statusCode === Constants.apiStatuses.API_UNAUTHORISED) {
        if (response.data?.message) {
          toast.error(response.data?.message);
        }
        else {
          Methods.unAuthorizaedLogout(true);
          configureStore().dispatch(isLogoutAction());
        }
      } else if (statusCode === Constants.apiStatuses.FORBIDDEN) {
        toast.error(response.data?.message);
        Methods.unAuthorizaedLogout(true);
        configureStore().dispatch(isLogoutAction());
      } else if (statusCode === Constants.apiStatuses.MULTIPLE_RESPONSES) {
        toast.warning(response.data?.message);
      } else if (statusCode === Constants.apiStatuses.UNABLE_PROCESS) {
        toast.error("Unable to process.");
      } else if (statusCode === Constants.apiStatuses.BAD_REQUEST) {
        const msg = Array.isArray(response.data?.message) ? response.data?.message?.[0] : response.data?.message;
        toast.error(msg);
      }
    } else if (request) {
      //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
      toast.info('The request was made but no response was received');
    }
  }

  return Promise.reject(error);
}

function createHttpInstance() {
  const instance = axios.create({
    baseURL: Methods.getDynamicUrl() + '/api_v1',
    headers: {
      'Content-Type': 'application/json'
    },
    validateStatus: (status) => status >= Constants.apiStatuses.API_SUCCESS && status <= Constants.apiStatuses.API_DELETE,
    timeout: 300000
  });

  instance.interceptors.request.use(async (config) => {
    return config;
  });
  
  instance.interceptors.response.use((res) => {
    return res;
  }, (error) => {
    return httpErrorHandler(error);
  });

  return instance;
}

const httpRequest: AxiosInstance = createHttpInstance();
export default httpRequest;